export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    authenticated,
    shouldRequestAuthentication,
    authenticationForm,
    nextRoute,
  } = storeToRefs(useAuthStore());

  if (!authenticated.value) {
    nextRoute.value = to;
    authenticationForm.value = 'login';
    shouldRequestAuthentication.value = true;

    if (from.name === to.name) {
      return navigateTo({ name: 'index', replace: true });
    }

    return abortNavigation();
  }
});
